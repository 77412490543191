import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Stack } from "@mui/material";
import { CellTower, Delete, Edit } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";

import { BaseContainer } from "../../components/BaseContainer";
import { toSpanish } from "../../helpers/toSpanish";
import { ModalNew } from "../../components/ModalNew";
import { ActionButton } from "../../components/ActionButton";
import { PaymentType } from "../../types/Billing";
import { connectionList } from "../connections/Connections";
import { BaseDataGrid } from "../../components/BaseDataGrid";

export const paymentList: PaymentType[] = [
  {
    id: 1,
    creation_date: "06/20/2024",
    expiration_date: "01/07/2024",
    payment_date: "01/07/2024",
    total: 30000,
    state: 1,
    type: 1,
    billing: {
      id: 1,
      iva: 21,
      cuit: "20536564321",
      connection: connectionList[0],
      billing_address: {
        id: 1,
        street: "Calle",
        number: "123",
        city: "Quitilipi",
        province: "Chaco",
      },
    },
  },
];

export const PaymentList = () => {
  let columns: GridColDef[] = [
    {
      field: "type",
      headerName: toSpanish("payment", "type"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "customer",
      headerName: toSpanish("payment", "customer"),
      minWidth: 200,
      flex: 30,
    },
    {
      field: "total",
      headerName: toSpanish("payment", "total"),
      description: "Precio total",
      minWidth: 50,
      flex: 30,
      renderCell: (params) => {
        return "$ " + params.row.total;
      },
    },
    {
      field: "iva",
      headerName: toSpanish("payment", "iva"),
      description: "Impuesto al Valor Agregado",
      minWidth: 20,
      flex: 30,
      renderCell: (params) => {
        return params.row.iva + "%";
      },
    },
    {
      field: "paid",
      headerName: toSpanish("payment", "paid"),
      minWidth: 100,
      flex: 30,
      renderCell: (params) => {
        return <Chip variant="filled" color="success" label="Pagado" />;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <ActionButton title="Eliminar pago" onClick={() => setOpenDeleteModal(true)} icon={<Delete />} color="error" />
            <ActionButton title="Editar pago" onClick={() => setOpenEditModal(true)} icon={<Edit />} />
          </Stack>
        );
      },
    },
  ];

  let navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [payment, setPayment] = useState<PaymentType>(paymentList[0]);

  useEffect(() => {
    document.title = "Facturación | CONNECT";
  }, []);

  return (
    <BaseContainer title="Facturación" icon={<CellTower />}>
      <BaseDataGrid rows={paymentList} columns={columns} />
      {/* <ModalNew
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        handleSubmit={() => {}}
        fieldsDialog={{ title: "Editar pago", accept: "Guardar cambios", iconTitle: <Edit /> }}
        loading={false}
      >
        <Grid container spacing={1}>
          <InputPatternNumber grid={12} entity="payment" name="cuit" setValue={setPayment} value={payment.cuit} format="##-########-#" req />
          <InputDate grid={6} entity="payment" name="creation_date" setValue={setPayment} value={payment.creation_date} req />
          <InputDate grid={6} entity="payment" name="expiration_date" setValue={setPayment} value={payment.expiration_date} req />
          <InputNumber grid={6} entity="payment" name="total" setValue={setPayment} value={payment.total} type="money" req />
          <InputNumber grid={6} entity="payment" name="iva" setValue={setPayment} value={payment.iva} type="percentage" req />
          <InputSelect grid={6} entity="payment" name="type" setValue={setPayment} value={payment.type} iterable={BILLING_TYPE_LIST} req />
          <InputRadio grid={6} entity="payment" name="paid" setValue={setPayment} value={payment.paid} req />
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5">Dirección de facturación</Typography>
        <Grid container spacing={1}>
          <InputField grid={8} name="street" entity="billing_address" setValue={setPayment} value={payment.billing.billing_address.street} req nested />
          <InputNumber grid={4} name="number" entity="billing_address" setValue={setPayment} value={payment.billing.billing_address.number} req nested />
          <InputField grid={6} name="city" entity="billing_address" setValue={setPayment} value={payment.billing.billing_address.city} req nested />
          <InputField grid={6} name="province" entity="billing_address" setValue={setPayment} value={payment.billing.billing_address.province} req nested />
        </Grid>
      </ModalNew> */}
      <ModalNew
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "Eliminar transacción",
          description: "Al eliminar esta transacción la factura pasará como estado NO PAGADO, si hay saldos no procesados estos serán eliminados.",
          accept: "Eliminar pago",
          iconTitle: <Delete />,
        }}
        loading={false}
      />
    </BaseContainer>
  );
};
