import React, { PropsWithChildren } from "react";
import { Backdrop, Box, Chip, CircularProgress, Paper, SvgIcon, Typography } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";

type PropsType = {
  title: string;
  icon: React.ReactNode;
  chip?: { title: string; status: boolean };
  mtDisabled?: boolean;
  mbDisabled?: boolean;
  loading?: boolean;
  error?: { isError: boolean; errorText: string };
};

export const BaseContainer = (props: PropsWithChildren<PropsType>) => {
  return props.loading ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : props.error && props.error.isError ? (
    <Box width="100%">
      <Paper elevation={4} sx={{ p: { xs: 2, md: 3 } }}>
        <Typography variant="h1" gutterBottom sx={{ fontWeight: "500", display: "flex", alignItems: "center", justifyContent: "center" }}>
          {props.error.errorText}
        </Typography>
      </Paper>
    </Box>
  ) : (
    <Box width="100%" sx={{ mt: props.mtDisabled ? 0 : { xs: 3, md: 6 }, mb: props.mbDisabled ? 0 : { xs: 3, md: 6 } }}>
      <Typography variant="h1" gutterBottom sx={{ fontWeight: "500", display: "flex", alignItems: "center", gap: 2 }}>
        <SvgIcon sx={{ fontSize: "2.6rem" }}>{props.icon}</SvgIcon> {props.title}
        {props.chip && (
          <Chip
            variant="outlined"
            color={props.chip.status ? "success" : "error"}
            icon={props.chip.status ? <Check /> : <Clear />}
            label={<strong>{props.chip.title}</strong>}
          />
        )}
      </Typography>
      <Paper elevation={4} sx={{ textAlign: { xs: "center", sm: "center", md: "inherit" }, p: { xs: 2, md: 3 } }}>
        {props.children}
      </Paper>
    </Box>
  );
};
