import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, Edit, Person, Save, ToggleOff } from "@mui/icons-material";

import { BaseContainer } from "../../components/BaseContainer";
import { NewUserType, ROLES_LIST, UserType } from "../../types/User";
import { DateDisplay } from "../../components/DateDisplay";
import { ModalNew } from "../../components/ModalNew";
import { InputField, InputSelect } from "../../components/Inputs";
import { toSpanish } from "../../helpers/toSpanish";
import { FieldDisplay } from "../../components/FieldDisplay";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";
import { areValuesEqual } from "../../helpers/functions";

const users: UserType[] = [
  {
    id: 1,
    name: "Usuario",
    lastname: "De Prueba",
    email: "usuariodeprueba@gmail.com",
    rol: "admin",
    created_at: "01/01/2024",
    updated_at: "01/01/2024",
  },
  {
    id: 2,
    name: "Juan",
    lastname: "Perez",
    email: "juanperez@gmail.com",
    rol: "operator",
    created_at: "02/20/2024",
    updated_at: "02/20/2024",
  },
];

export const User = () => {
  const { idUser } = useParams();
  // const { loading, data: user, error } = useAxios<UserType>({ url: `${endpoints.getUser}/${idUser}` });
  const [editing, setEditing] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [editingUser, setEditingUser] = useState<UserType>(users[Number(idUser) - 1]);
  const [putUserData, setPutUserData] = useState<NewUserType>();
  const {
    data: userModify,
    loading: loadingPutUser,
    error: modifyError,
  } = useAxios<UserType>({
    // url: `${endpoints.getUser}/${idUser}`,
    url: `${endpoints.getDataUser}/${idUser}`,
    method: "put",
    data: putUserData,
    msgOnSuccess: "Usuario actualizado",
  });

  function cancelEdit() {
    setEditingUser(users[Number(idUser) - 1]);
    setEditing(false);
  }

  function saveEdit() {
    setPutUserData(editingUser);
  }

  useEffect(() => {
    document.title = "Usuario #" + idUser + " | CONNECT";
  }, [idUser]);

  // useEffect(() => {
  //   setEditingUser(user);
  // }, [user]);

  useEffect(() => {
    console.log("a");
    if (!modifyError && userModify) {
      setEditingUser(userModify);
      setEditing(false);
    }
  }, [userModify, modifyError]);

  return (
    <BaseContainer
      title={putUserData ? `${userModify?.name} ${userModify?.lastname}` : `Usuario` /*`${user?.name} ${user?.lastname}` */}
      icon={<Person />}
      chip={{ title: "ACTIVO", status: true }}
      // loading={loading}
      // error={{ isError: error, errorText: "Usuario no encontrado" }}
    >
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" color="warning" startIcon={<Edit />} onClick={() => setEditing(true)} disabled={editing}>
            Editar datos
          </Button>
          {editingUser?.rol !== "admin" && (
            <Button variant="outlined" color="error" startIcon={<ToggleOff />} onClick={() => setOpenDisableModal(true)} disabled={editing}>
              Desactivar usuario
            </Button>
          )}
        </Stack>
        <DateDisplay createdAt={editingUser?.created_at} updatedAt={editingUser?.updated_at} />
      </Box>
      <Grid container spacing={2}>
        {editing ? (
          <>
            <InputField grid={6} entity="user" name="name" setValue={setEditingUser} value={editingUser?.name} req />
            <InputField grid={6} entity="user" name="lastname" setValue={setEditingUser} value={editingUser?.lastname} req />
            <InputField grid={6} entity="user" name="email" setValue={setEditingUser} value={editingUser?.email} req />
            <InputSelect grid={6} entity="user" name="rol" setValue={setEditingUser} value={editingUser?.rol} req iterable={ROLES_LIST} />
          </>
        ) : (
          <>
            <FieldDisplay description={toSpanish("user", "name")} text={editingUser?.name} grid={6} />
            <FieldDisplay description={toSpanish("user", "lastname")} text={editingUser?.lastname} grid={6} />
            <FieldDisplay description={toSpanish("user", "email")} text={editingUser?.email} grid={6} />
            <FieldDisplay description={toSpanish("user", "rol")} text={toSpanish("user_role", editingUser?.rol)} grid={6} />
          </>
        )}
      </Grid>

      {editing && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 2 }}>
          <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={cancelEdit}>
            Cancelar
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            startIcon={<Save />}
            onClick={saveEdit}
            loading={loadingPutUser}
            // disabled={areValuesEqual(editingUser, user)}
          >
            Guardar cambios
          </LoadingButton>
        </Box>
      )}
      <ModalNew
        open={openDisableModal}
        handleClose={() => setOpenDisableModal(!openDisableModal)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "¿Está seguro que quiere desactivar al usuario?",
          accept: "Aceptar",
          description: "Si lo desactiva, el usuario ya no podrá ingresar al sistema. Puede volver a activarlo en cualquier momento.",
        }}
        loading={false}
      />
    </BaseContainer>
  );
};
