import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Email, Password } from "@mui/icons-material";

import "../../assets/styles/login.css";
import loginImg from "../../assets/images/logo.svg";
import { LoggedType, LoginUserType } from "../../types/User";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";

export const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<LoginUserType>({ user: "", password: "" });
  const [postUserData, setPostUserData] = useState<LoginUserType>();

  const handleFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const { data: userData, loading } = useAxios<LoggedType>({
    url: endpoints.login,
    method: "post",
    data: postUserData,
    auth: false,
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setPostUserData(user);
  }

  useEffect(() => {
    if (userData && userData.hasOwnProperty("access_token")) {
      window.localStorage.setItem("jwt", JSON.stringify(userData.access_token));
      window.localStorage.setItem("exp", JSON.stringify(userData.expires_at));
      navigate("/dashboard", { replace: true, state: { message: `¡Bienvenido!` } });
    }
  }, [userData]);

  return (
    <>
      <div className="container">
        <div className="forms-container">
          <div className="signin-signup">
            <form onSubmit={handleSubmit} className="sign-in-form">
              <h2 className="title">Iniciar sesión</h2>
              <div className="input-field">
                <Email />
                <input onChange={handleFieldChange} name="user" type="text" placeholder="Usuario/Correo electrónico" />
              </div>
              <div className="input-field">
                <Password />
                <input onChange={handleFieldChange} type="password" name="password" placeholder="Contraseña" />
              </div>
              <LoadingButton type="submit" className="btn solid" disabled={user.user === "" || user.password === ""} loading={loading}>
                Iniciar sesión
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <img src={loginImg} className="image" alt="Logo Connect" />
        </div>
      </div>
    </>
  );
};
