import { jwtDecode } from "jwt-decode";
import { UserJwtType } from "../types/User";

export const jsonWebToken = () => {
  const localStorageItem = window.localStorage.getItem("jwt");
  if (localStorageItem) {
    try {
      return JSON.parse(localStorageItem);
    } catch {
      return null;
    }
  } else {
    return null;
  }
};

export const userData = () => {
  return jwtDecode(jsonWebToken()) as UserJwtType;
};

type HeaderType = {
  headers: {
    Authorization: string;
    "Content-Type"?: string;
  };
};

export const authHeader = (type?: string) => {
  const token = jsonWebToken();
  let headers: HeaderType = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (type) {
    headers.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": type,
    };
  }

  return headers;
};
