import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Autocomplete, Box, Button, Chip, Divider, Grid, Modal, TextField, Typography } from "@mui/material";
import { Cancel, Edit, PersonPinCircle, Place, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Map, Marker } from "react-map-gl";
import dayjs from "dayjs";

import { emailRegex, getStateConnectionColor } from "../../helpers/functions";
import { toSpanish } from "../../helpers/toSpanish";
import { connectionList } from "../connections/Connections";
import { ConnectionType, NewConnectionType } from "../../types/Connection";
import { FieldDisplay } from "../../components/FieldDisplay";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";
import { InputDate, InputField, InputNumber, InputPatternNumber, InputSelect } from "../../components/Inputs";
import { serviceList } from "../services/ServiceList";
import { ConfirmButtons } from "../../components/ActionButton";

export const CustomerServices = () => {
  const { idCustomer } = useParams();
  const { loading, data: customerConnections, error } = useAxios<ConnectionType[]>({ url: `${endpoints.connections}?customer=${idCustomer}` });
  const [selectedConnection, setSelectedConnection] = useState<ConnectionType | null>(connectionList.length === 1 ? connectionList[0] : null);
  const [openModal, setOpenModal] = useState(false);
  const [editing, setEditing] = useState(false);

  function handleCloseModal() {
    setOpenModal(!openModal);
  }

  const [editingConnection, setEditingConnection] = useState<ConnectionType>();
  const [putConnectionData, setPutConnectionData] = useState<NewConnectionType>();
  const {
    data: connectionModify,
    loading: loadingPutConnection,
    error: modifyError,
  } = useAxios<ConnectionType>({
    url: `${endpoints.connections}/${selectedConnection?.id}`,
    method: "put",
    data: putConnectionData,
    msgOnSuccess: "Conexión actualizada",
  });

  function cancelEdit() {
    setEditingConnection(connectionList[Number(selectedConnection?.id) - 1]);
    setEditing(false);
  }

  function saveEdit() {
    if (editingConnection) {
      setPutConnectionData({
        ...editingConnection,
        connection_date: dayjs(editingConnection.connection_date),
        serviceId: editingConnection.service.id,
        customerId: editingConnection.customer.id,
      });
    }
  }

  useEffect(() => {
    if (selectedConnection) {
      setEditingConnection(selectedConnection);
    }
  }, [selectedConnection]);

  useEffect(() => {
    if (!modifyError && connectionModify) {
      setEditingConnection(connectionModify);
      setEditing(false);
    }
  }, [connectionModify, modifyError]);

  return connectionList.length > 0 ? (
    <>
      {connectionList.length > 1 && (
        <Autocomplete
          loading={false}
          options={connectionList}
          getOptionLabel={(option) =>
            `${option.connection_address.street} (${option.service.name}) - ${toSpanish("connection_states", option.state.toString())}`
          }
          // value={}
          onChange={(event: React.SyntheticEvent<Element, Event>, newValue: ConnectionType | null) => {
            setSelectedConnection(newValue);
          }}
          sx={{ width: "100%" }}
          openOnFocus
          autoHighlight
          disableClearable
          renderInput={(params) => <TextField {...params} label="Seleccione la conexión que quiere ver" />}
        />
      )}
      {editingConnection &&
        (!editing ? (
          <>
            <Grid container>
              <Box my={2} display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography variant="h5">
                  {editingConnection.service.name}
                  <Chip
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color={getStateConnectionColor(editingConnection.state)}
                    label={<strong>{toSpanish("connection_states", editingConnection.state.toString())}</strong>}
                  />
                </Typography>
                <Button variant="outlined" color="warning" startIcon={<Edit />} onClick={() => setEditing(true)} disabled={editing}>
                  Editar datos
                </Button>
              </Box>
              <Grid container spacing={2}>
                <FieldDisplay grid={6} description={toSpanish("connection", "connection_date")} text={editingConnection.connection_date} />
                <FieldDisplay grid={6} description={toSpanish("connection", "ip")} text={editingConnection.ip} />
                <FieldDisplay grid={4} description={toSpanish("connection_address", "email")} text={editingConnection.connection_address.email} />
                <FieldDisplay grid={4} description={toSpanish("connection_address", "telephone_1")} text={editingConnection.connection_address.telephone_1} />
                <FieldDisplay
                  grid={4}
                  description={toSpanish("connection_address", "telephone_2")}
                  text={editingConnection.connection_address.telephone_2 || "N/A"}
                />
                <FieldDisplay
                  grid={8}
                  description={toSpanish("connection", "connection_address")}
                  text={`${editingConnection.connection_address.street} ${editingConnection.connection_address.number}`}
                />
                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button variant="outlined" color="success" startIcon={<Place />} onClick={() => setOpenModal(true)}>
                    Ver en el mapa
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={1} justifyContent="center">
              <InputDate
                grid={{ md: 4.5, lg: 4 }}
                entity="connection"
                name="connection_date"
                setValue={setEditingConnection}
                value={dayjs(editingConnection.connection_date)}
                req
              />
              <InputSelect
                grid={{ md: 7.5, lg: 8 }}
                entity="connection"
                name="service"
                setValue={setEditingConnection}
                value={editingConnection.service.id}
                req
                iterable={serviceList.map((serv) => {
                  return { value: serv.id, text: `${serv.name} - ⬇${serv.download}Mbps/⬆${serv.upload}Mbps` };
                })}
              />
              <InputPatternNumber
                grid={{ md: 6, lg: 4 }}
                name="telephone_1"
                entity="connection_address"
                setValue={setEditingConnection}
                value={editingConnection.connection_address.telephone_1}
                req
                nested
                format="(####) ##-####"
              />
              <InputPatternNumber
                grid={{ md: 6, lg: 4 }}
                name="telephone_2"
                entity="connection_address"
                setValue={setEditingConnection}
                value={editingConnection.connection_address.telephone_2}
                nested
                format="(####) ##-####"
              />
              <InputField
                grid={{ md: 12, lg: 4 }}
                name="email"
                entity="connection_address"
                setValue={setEditingConnection}
                value={editingConnection.connection_address.email}
                email
                error={!emailRegex.test(editingConnection.connection_address.email) && editingConnection.connection_address.email.length > 1}
                helperText={
                  !emailRegex.test(editingConnection.connection_address.email) && editingConnection.connection_address.email.length > 1
                    ? "El email tiene un formato incorrecto"
                    : ""
                }
                req
                nested
              />
              <InputField
                grid={{ sm: 7, md: 7, lg: 7 }}
                name="street"
                entity="connection_address"
                setValue={setEditingConnection}
                value={editingConnection.connection_address.street}
                req
                nested
              />
              <InputNumber
                grid={{ sm: 2, md: 2, lg: 2 }}
                name="number"
                entity="connection_address"
                setValue={setEditingConnection}
                value={editingConnection.connection_address.number}
                req
                nested
              />
              <Grid item sm={3} md={3} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button variant="outlined" color="warning" startIcon={<Place />} onClick={() => setOpenModal(true)}>
                  Editar coordenadas
                </Button>
              </Grid>
            </Grid>
            <ConfirmButtons cancel={cancelEdit} save={saveEdit} loading={loadingPutConnection} />
          </>
        ))}
      {editingConnection && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <ViewMap connection={editingConnection} editing={editing} close={handleCloseModal} />
        </Modal>
      )}
    </>
  ) : (
    <Alert severity="info">El cliente no tiene ningún servicio asociado</Alert>
  );
};

const ViewMap = ({ connection, editing, close }: { connection: ConnectionType; editing: boolean; close: () => void }) => {
  const lat = Number(connection.connection_address.latitude);
  const long = Number(connection.connection_address.longitude);
  const [position, setPosition] = useState({ lat, long });

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{ latitude: position.lat, longitude: position.long, zoom: 16 }}
        style={{ width: "60vw", height: "60vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <Marker
          longitude={position.long}
          latitude={position.lat}
          anchor="bottom"
          draggable={editing}
          onDragEnd={(e) =>
            setPosition({
              lat: e.lngLat.lat,
              long: e.lngLat.lng,
            })
          }
        >
          <PersonPinCircle sx={{ fontSize: "3rem" }} color="secondary" />
        </Marker>
      </Map>
      <Box display="flex" justifyContent="space-around" mt={2}>
        <Typography variant="h6">
          {toSpanish("connection_address", "latitude")}: <strong>{position.lat}</strong>
        </Typography>
        <Typography variant="h6">
          {toSpanish("connection_address", "longitude")}: <strong>{position.long}</strong>
        </Typography>
      </Box>
      {editing && <ConfirmButtons cancel={close} save={() => {}} loading={false} disabled={{ actualObj: { lat, long }, newObj: position }} />}
    </Box>
  );
};
