import React, { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, Save } from "@mui/icons-material";

import { areValuesEqual } from "../helpers/functions";

type ActionProps = {
  title: string;
  icon: ReactNode;
  link?: string;
  color?: "primary" | "inherit" | "default" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  size?: "small" | "medium" | "large";
};

export const ActionButton = ({ title, icon, link = "", color = "primary", disabled = false, onClick, size = "medium" }: ActionProps) => {
  return (
    <Tooltip
      title={title}
      disableInteractive
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      <IconButton component={Link} to={link} color={color} disabled={disabled} onClick={onClick} size={size}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

type ConfirmProps = {
  cancel: () => void;
  save: () => void;
  loading: boolean;
  disabled?: { newObj: any; actualObj: any };
};

export const ConfirmButtons = ({ cancel, save, loading, disabled }: ConfirmProps) => {
  useEffect(() => {
    console.log(disabled);
  }, [disabled]);

  return (
    <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 2 }}>
      <LoadingButton variant="outlined" color="error" startIcon={<Cancel />} onClick={cancel}>
        Cancelar
      </LoadingButton>
      <LoadingButton
        variant="outlined"
        color="success"
        startIcon={<Save />}
        onClick={save}
        loading={loading}
        disabled={disabled ? areValuesEqual(disabled.newObj, disabled.actualObj) : false}
      >
        Guardar cambios
      </LoadingButton>
    </Box>
  );
};
