import React, { useRef, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Container } from "@mui/material";
// import endpoints from "../helpers/endpoints";
import { useLocation } from "react-router";
import { Sidebar } from "../views/layouts/Sidebar";

export const PrivateRoute = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const flag = useRef(true);

  useEffect(() => {
    if (state && flag.current) {
      // displayToast(state);
      window.history.replaceState(null, "");
      flag.current = false;
    }
  }, [state]);

  return (
    <>
      <Sidebar />
      <Container className="home">
        <Outlet />
      </Container>
    </>
  );
};
