export function handleChangeValue<T>(field: string, value: any, setState: React.Dispatch<React.SetStateAction<T>>) {
  setState((prevState: T) => {
    return { ...prevState, [field as keyof T]: value };
  });
}

export function handleChangeNestedValue<T>(field: string, nestedField: string, value: any, setState: React.Dispatch<React.SetStateAction<T>>) {
  setState((prevState: T) => {
    return { ...prevState, [nestedField as keyof T]: { ...prevState[nestedField as keyof T], [field as keyof T]: value } };
  });
}

export function areValuesEqual<T extends object>(newObj: T, actualObj: Partial<T>): boolean {
  const newObjKeys = Object.keys(newObj) as (keyof T)[];

  for (const key of newObjKeys) {
    if (newObj[key] !== actualObj[key]) {
      return false;
    }
  }

  return true;
}

export function getStateConnectionColor(state: number) {
  switch (state) {
    case 0:
      return "info";
    case 1:
      return "success";
    case 2:
      return "warning";
    case 3:
      return "error";
    default:
      return "default";
  }
}

export const cuilFormat = (val: string) => {
  if (val === "") return "";
  const cleanVal = val.replace(/\D/g, "");

  const part1 = cleanVal.substring(0, 2);
  const part2 = cleanVal.substring(2, 10);
  const part3 = cleanVal.substring(10, 11);

  let formatted = part1;
  if (part2) {
    formatted += `-${part2}`;
  }
  if (part3) {
    formatted += `-${part3}`;
  }

  return formatted;
};

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
