export type RolesType = "admin" | "operator";
export const ROLES_LIST = [
  {
    value: "admin",
    text: "Administrador",
  },
  {
    value: "operator",
    text: "Operador",
  },
];

export type LoginUserType = {
  user: string;
  password: string;
};
export type LoggedType = {
  access_token: string;
  token_type: string;
  expires_at: string;
};
export type UserType = {
  id: number;
  name: string;
  lastname: string;
  email: string;
  rol: RolesType;
  created_at: string;
  updated_at: string;
};
// { name, user, password, password_confirmation, level }
export type NewUserType = {
  name: string;
  lastname: string;
  email: string;
  rol: RolesType | undefined;
};
export type UserJwtType = {
  aud: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  sub: string;
};
