interface SpanishDictionary {
  [key: string]: { [key: string]: string };
}

const SPANISH: SpanishDictionary = {
  general: { id: "ID", created_at: "Creado", updated_at: "Modificado" },
  user_role: { admin: "Administrador", operator: "Operador" },
  user: {
    name: "Nombre",
    lastname: "Apellido",
    email: "Correo electrónico",
    rol: "Tipo",
  },
  customer: {
    dni: "DNI",
    firstname: "Nombre",
    lastname: "Apellido",
    customer_address: "Dirección principal",
  },
  customer_address: {
    street: "Calle",
    number: "Altura",
    city: "Ciudad",
    province: "Provincia",
    telephone_1: "Teléfono",
    telephone_2: "Teléfono alternativo",
    email: "Correo electrónico",
  },
  service: {
    name: "Nombre",
    description: "Descripción",
    upload: "Subida",
    download: "Bajada",
    price: "Precio",
    iva: "IVA",
    bandwidth: "Ancho de banda",
  },
  connection: {
    state: "Estado",
    ip: "IP",
    connection_date: "Fecha de la solicitud",
    service: "Servicio",
    customer: "Cliente",
    connection_address: "Dirección de la conexión",
  },
  connection_states: {
    0: "Pendiente de conexión",
    1: "Activo",
    2: "Suspendido",
    3: "Retirado",
  },
  connection_address: {
    latitude: "Latitud",
    longitude: "Longitud",
    street: "Calle",
    number: "Altura",
    telephone_1: "Teléfono",
    telephone_2: "Teléfono alternativo",
    email: "Correo electrónico",
  },
  billing: {
    cuit: "CUIL/CUIT",
    connection: "Conexión",
    iva: "IVA",
    billing_address: "Dirección de facturación",
  },
  payment: {
    creation_date: "Fecha de creación",
    expiration_date: "Fecha de expiración",
    payment_date: "Fecha de pago",
    total: "Total",
    state: "Estado",
    type: "Tipo",
    billing: "Facturación",
  },
  billing_address: {
    street: "Calle",
    number: "Altura",
    city: "Ciudad",
    province: "Provincia",
  },
};

export function toSpanish(entity: string, word: string) {
  if (SPANISH.hasOwnProperty(entity)) {
    return SPANISH[entity][word];
  } else {
    return word;
  }
}
