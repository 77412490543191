import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { Add, Build, CellTower, Delete } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";

import { BaseContainer } from "../../components/BaseContainer";
import { toSpanish } from "../../helpers/toSpanish";
import { NewServiceType, ServiceType } from "../../types/Service";
import { ModalNew } from "../../components/ModalNew";
import { InputField, InputNumber } from "../../components/Inputs";
import { ActionButton } from "../../components/ActionButton";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";

export const serviceList: ServiceType[] = [
  {
    id: 1,
    name: "Plan 300mb",
    description: "Descripción del servicio 1",
    upload: "100",
    download: "80",
    price: 26000,
    iva: 21,
    amount_active: 120,
    amount_suspended: 8,
  },
  {
    id: 2,
    name: "Plan 100mb",
    description: "Descripción del servicio 2",
    upload: "100",
    download: "80",
    price: 20000,
    iva: 21,
    amount_active: 73,
    amount_suspended: 12,
  },
];

const initialValue: NewServiceType = {
  name: "",
  description: "",
  upload: "",
  download: "",
  price: "",
  iva: "",
};

export const ServiceList = () => {
  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: toSpanish("general", "id"),
      width: 20,
    },
    {
      field: "name",
      headerName: toSpanish("service", "name"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "description",
      headerName: toSpanish("service", "description"),
      minWidth: 200,
      flex: 30,
    },
    {
      field: "price",
      headerName: toSpanish("service", "price"),
      description: "Precio total",
      minWidth: 50,
      flex: 30,
      renderCell: (params) => {
        return "$ " + params.row.price;
      },
    },
    {
      field: "iva",
      headerName: toSpanish("service", "iva"),
      description: "Impuesto al Valor Agregado",
      minWidth: 20,
      flex: 30,
      renderCell: (params) => {
        return params.row.iva + "%";
      },
    },
    {
      field: "bandwidth",
      headerName: toSpanish("service", "bandwidth"),
      width: 165,
      valueGetter: (value, row: ServiceType) => {
        return `⬇${row.download}Mbps/⬆${row.upload}Mbps`;
      },
    },
    {
      field: "amount_active",
      headerName: "Activos",
      minWidth: 100,
      flex: 30,
    },
    {
      field: "amount_suspended",
      headerName: "Suspendidos",
      minWidth: 100,
      flex: 30,
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <ActionButton title="Ver detalles" link={"./" + params.row.id} icon={<Build />} />
            <ActionButton title="Eliminar" onClick={() => setOpenDeleteModal(true)} icon={<Delete />} color="error" />
          </Stack>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newService, setNewService] = useState<NewServiceType>(initialValue);
  // const { data: services, loading } = useAxios<ServiceType[]>({ url: endpoints.services, initialData: [] });
  const [postServiceData, setPostServiceData] = useState<NewServiceType>();
  const {
    data: serviceNew,
    loading: loadingPostService,
    error: postError,
  } = useAxios<ServiceType>({
    url: `${endpoints.services}`,
    method: "post",
    data: postServiceData,
    msgOnSuccess: "Servicio creado",
  });

  function handleCloseModal() {
    setNewService(initialValue);
    setOpenModal(!openModal);
  }

  function createNewService(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setPostServiceData(newService);
  }

  useEffect(() => {
    document.title = "Servicios | CONNECT";
  }, []);

  useEffect(() => {
    if (!postError && serviceNew) {
      // refetchServices();
      setOpenModal(false);
      setNewService(initialValue);
    }
  }, [serviceNew, postError]);

  return (
    <BaseContainer title="Servicios" icon={<CellTower />}>
      <BaseDataGrid
        rows={serviceList}
        columns={columns}
        /* loading={loading} */ buttons={[
          <Button variant="text" size="small" color="success" startIcon={<Add />} onClick={() => setOpenModal(true)}>
            Nuevo servicio
          </Button>,
        ]}
      />
      <ModalNew
        open={openModal}
        handleClose={handleCloseModal}
        handleSubmit={createNewService}
        fieldsDialog={{ title: "Crear servicio", accept: "Crear", iconTitle: <Add /> }}
        loading={loadingPostService}
      >
        <InputField entity="service" name="name" setValue={setNewService} value={newService.name} req />
        <InputField entity="service" name="description" setValue={setNewService} value={newService.description} multi req />
        <InputNumber entity="service" name="upload" setValue={setNewService} value={newService.upload} req />
        <InputNumber entity="service" name="download" setValue={setNewService} value={newService.download} req />
        <InputNumber entity="service" name="price" setValue={setNewService} value={newService.price} type="money" req />
        <InputNumber entity="service" name="iva" setValue={setNewService} value={newService.iva} type="percentage" req />
      </ModalNew>
      <ModalNew
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "¿Está seguro que quiere eliminar el servicio?",
          description: "Esta acción es irreversible",
          accept: "Sí",
          iconTitle: <Delete />,
        }}
        loading={false}
      />
    </BaseContainer>
  );
};
