import React, { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Map, Marker } from "react-map-gl";
import { Autocomplete, TextField } from "@mui/material";
import { Map as MapIcon, PersonPinCircle } from "@mui/icons-material";

import { connectionList } from "./Connections";
import { BaseContainer } from "../../components/BaseContainer";

type CityType = {
  city: string;
  latitude: number;
  longitude: number;
};

const cities: CityType[] = [
  { city: "Pcia. Roque Sáenz Peña", latitude: -26.79124947521646, longitude: -60.44106908164302 },
  { city: "Quitilipi", latitude: -26.870880469601765, longitude: -60.21509936843445 },
];

export const ConnectionMap = () => {
  const [referencePoint, setReferencePoint] = useState<CityType>();

  useEffect(() => {
    document.title = "Conexiones | CONNECT";
  }, []);

  const markerRef = useRef<mapboxgl.Marker>();

  const markers = useMemo(() => {
    return connectionList.map((connection) => (
      <Marker
        key={connection.id}
        longitude={Number(connection.connection_address.longitude)}
        latitude={Number(connection.connection_address.latitude)}
        anchor="bottom"
        popup={new mapboxgl.Popup().setHTML(`
          <a href="/clientes/${connection.customer.id}"><h3>${connection.customer.firstname} ${connection.customer.lastname}</h3></a>
          <h5>${connection.connection_address.street}</h5>
          <h5>${connection.service.name}</h5>
        `)}
        ref={markerRef as React.LegacyRef<mapboxgl.Marker>}
      >
        <PersonPinCircle sx={{ fontSize: "3rem" }} color="secondary" />
      </Marker>
    ));
  }, [connectionList]);

  return (
    <BaseContainer title="Mapa de conexiones y clientes" icon={<MapIcon />} mtDisabled>
      <Autocomplete
        loading={false}
        options={cities}
        getOptionLabel={(option) => option.city}
        value={referencePoint}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: CityType | null) => {
          if (newValue) {
            setReferencePoint(newValue);
          }
        }}
        sx={{ width: "100%", mb: 2 }}
        openOnFocus
        autoHighlight
        disableClearable
        renderInput={(params) => <TextField {...params} label="Ciudad" />}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{
          latitude: -26.78522,
          longitude: -60.43876,
          zoom: 13,
        }}
        style={{ width: "100%", height: "500px" }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        {markers}
      </Map>
    </BaseContainer>
  );
};

// const CustomerMarker = (connection: ConnectionType) => {
//   return (
//     <>
//       <h3>${connection.customer.name}</h3>
//       <h5>${connection.address.street}</h5>
//       <h5>${connection.service}</h5>
//       <Chip
//         sx={{ ml: 2 }}
//         variant="outlined"
//         color={getStateConnectionColor(connection.state)}
//         label={<strong>{toSpanish("connection_states", connection.state.toString())}</strong>}
//       />
//     </>
//   );
// };
