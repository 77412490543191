import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { Build, PowerSettingsNew, SettingsInputComponent } from "@mui/icons-material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";

import { BaseContainer } from "../../components/BaseContainer";
import { toSpanish } from "../../helpers/toSpanish";
import { ConnectionType } from "../../types/Connection";
import { serviceList } from "../services/ServiceList";
import { CUSTOMER_LIST } from "../customers/CustomerList";
import { getStateConnectionColor } from "../../helpers/functions";
import { ModalNew } from "../../components/ModalNew";

export const connectionList: ConnectionType[] = [
  {
    id: 1,
    state: 0,
    ip: "192.168.0.1",
    connection_date: "05/20/2024",
    service: serviceList[0],
    customer: CUSTOMER_LIST[0],
    connection_address: {
      id: 1,
      latitude: "-26.7932958",
      longitude: "-60.4376425",
      street: "Lavalle",
      number: "500",
      telephone_1: "3735400000",
      telephone_2: "3735400001",
      email: "clienteprueba@gmail.com",
    },
  },
  {
    id: 2,
    state: 1,
    ip: "192.158.32.1",
    connection_date: "05/15/2024",
    service: serviceList[1],
    customer: CUSTOMER_LIST[1],
    connection_address: {
      id: 2,
      latitude: "-26.78522",
      longitude: "-60.43876",
      street: "Lavalle",
      number: "355",
      telephone_1: "3735300000",
      telephone_2: "3735300001",
      email: "juliofuentes@gmail.com",
    },
  },
];

export const Connections = () => {
  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: toSpanish("general", "id"),
      description: "Identificador",
      width: 20,
    },
    {
      field: "customer",
      headerName: toSpanish("connection", "customer"),
      description: "Persona que contrató el servicio",
      minWidth: 100,
      flex: 30,
      valueGetter: (value, row: ConnectionType) => {
        return `${row.customer.firstname} ${row.customer.lastname}`;
      },
      renderCell: (params: { row: ConnectionType }) => {
        return (
          <Tooltip title={`DNI: ${params.row.customer.dni}`}>
            <span>
              {params.row.customer.firstname} {params.row.customer.lastname}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "service",
      headerName: toSpanish("connection", "service"),
      description: "Servicio contratado",
      minWidth: 100,
      flex: 20,
      valueGetter: (value, row: ConnectionType) => {
        return row.service.name;
      },
      renderCell: (params: { row: ConnectionType }) => {
        return (
          <Tooltip title={`⬇${params.row.service.download}Mbps/⬆${params.row.service.upload}Mbps - ${params.row.service.description}`}>
            <span>{params.row.service.name}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "address",
      headerName: toSpanish("connection", "address"),
      description: "Calle",
      minWidth: 100,
      flex: 30,
      valueGetter: (value, row: ConnectionType) => {
        return `${row.connection_address.street} ${row.connection_address.number}`;
      },
    },
    {
      field: "ip",
      headerName: toSpanish("connection", "ip"),
      description: "Dirección IP",
      flex: 20,
    },
    {
      field: "connection_date",
      headerName: toSpanish("connection", "connection_date"),
      description: "Fecha de la conexión",
      width: 160,
    },
    {
      field: "state",
      headerName: toSpanish("connection", "state"),
      description: "Estado de la conexión",
      width: 190,
      renderCell: (params: { row: ConnectionType }) => {
        return (
          <Chip
            variant="outlined"
            color={getStateConnectionColor(params.row.state)}
            label={<strong>{toSpanish("connection_states", params.row.state.toString())}</strong>}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acción",
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Tooltip title="Ver detalles" disableInteractive>
              <IconButton
                aria-label="ver-detalles"
                onClick={() => {
                  navigate("./" + params.row.id);
                }}
                color="primary"
              >
                <Build aria-label="ver-detalles" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Desactivar conexión" disableInteractive>
              <IconButton aria-label="desactivar-conexion" onClick={() => setOpenDisableModal(true)} color="primary">
                <PowerSettingsNew aria-label="desactivar-conexion" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const [openDisableModal, setOpenDisableModal] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Conexiones | CONNECT";
  }, []);

  return (
    <>
      <BaseContainer title="Conexiones" icon={<SettingsInputComponent />}>
        <DataGrid
          autoHeight
          rows={connectionList}
          columns={columns}
          rowBufferPx={10}
          pageSizeOptions={[10, 25, 50]}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } } }}
          disableRowSelectionOnClick
          sx={{ mt: 2 }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
      </BaseContainer>
      <ModalNew
        open={openDisableModal}
        handleClose={() => setOpenDisableModal(!openDisableModal)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "¿Está seguro que quiere desactivar la conexión?",
          accept: "Aceptar",
          description: "Si lo desactiva, la conexión dejará de estar activa. Puede volver a activarlo en cualquier momento.",
        }}
        loading={false}
      />
    </>
  );
};
