import React, { useEffect, useState } from "react";
import { Button, Stack, Tooltip } from "@mui/material";
import { Build, PeopleAlt, PersonAddAlt1, PersonOff } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";

import { BaseContainer } from "../../components/BaseContainer";
import { toSpanish } from "../../helpers/toSpanish";
import { convertToDate } from "../../helpers/formats";
import { NewUserType, ROLES_LIST, UserType } from "../../types/User";
import { ModalNew } from "../../components/ModalNew";
import { InputField, InputSelect } from "../../components/Inputs";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { ActionButton } from "../../components/ActionButton";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";

const users: UserType[] = [
  {
    id: 1,
    name: "Usuario",
    lastname: "De Prueba",
    email: "usuariodeprueba@gmail.com",
    rol: "admin",
    created_at: "01/01/2024",
    updated_at: "01/01/2024",
  },
  {
    id: 2,
    name: "Juan",
    lastname: "Perez",
    rol: "operator",
    email: "juanperez@gmail.com",
    created_at: "02/20/2024",
    updated_at: "02/20/2024",
  },
];

const initialValue: NewUserType = {
  name: "",
  lastname: "",
  email: "",
  rol: undefined,
};

export const Users = () => {
  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: toSpanish("general", "id"),
      width: 20,
    },
    {
      field: "name",
      headerName: toSpanish("user", "name"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "lastname",
      headerName: toSpanish("user", "lastname"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "email",
      headerName: toSpanish("user", "email"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "rol",
      headerName: toSpanish("user", "rol"),
      minWidth: 100,
      flex: 30,
      valueGetter: (value, row) => {
        return toSpanish("user_role", value);
      },
    },
    {
      field: "created_at",
      headerName: toSpanish("general", "created_at"),
      description: "Fecha de alta",
      width: 110,
      align: "center",
      headerAlign: "center",
      valueGetter: (value, row) => {
        return new Date(row.created_at);
      },
      renderCell: (params) => {
        const [date, hour] = convertToDate(params.row.created_at);
        return (
          <Tooltip title={hour}>
            <span>{date}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acción",
      type: "actions",
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <ActionButton title="Ver detalles" link={"./" + params.row.id} icon={<Build />} />
            <ActionButton title="Desactivar usuario" onClick={() => setOpenDisableModal(true)} icon={<PersonOff />} color="error" />
          </Stack>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState<NewUserType>(initialValue);
  // const { data: users, loading, refetch: refetchUsers } = useAxios<UserType[]>({ url: endpoints.getUsers, initialData: [] });
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [postUserData, setPostUserData] = useState<NewUserType>();
  const {
    data: userNew,
    loading: loadingPostUser,
    error: postError,
  } = useAxios<UserType>({
    url: `${endpoints.registerUser}`,
    method: "post",
    data: postUserData,
    msgOnSuccess: "Usuario creado",
  });

  function handleCloseModal() {
    setNewUser(initialValue);
    setOpenModal(!openModal);
  }

  function createNewUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setPostUserData(newUser);
  }

  useEffect(() => {
    document.title = "Usuarios | CONNECT";
  }, []);

  useEffect(() => {
    if (!postError && userNew) {
      // refetchUsers();
      setOpenModal(false);
      setNewUser(initialValue);
    }
  }, [userNew, postError]);

  return (
    <BaseContainer title="Usuarios" icon={<PeopleAlt />}>
      <BaseDataGrid
        rows={users}
        columns={columns}
        buttons={[
          <Button variant="text" size="small" color="success" startIcon={<PersonAddAlt1 />} onClick={() => setOpenModal(true)}>
            Nuevo usuario
          </Button>,
        ]}
      />
      <ModalNew
        open={openModal}
        handleClose={handleCloseModal}
        handleSubmit={createNewUser}
        fieldsDialog={{ title: "Crear usuario", accept: "Crear", iconTitle: <PersonAddAlt1 /> }}
        loading={loadingPostUser}
      >
        <InputField entity="user" name="name" setValue={setNewUser} value={newUser.name} req />
        <InputField entity="user" name="lastname" setValue={setNewUser} value={newUser.lastname} req />
        <InputField entity="user" name="email" setValue={setNewUser} value={newUser.email} req />
        <InputSelect entity="user" name="rol" setValue={setNewUser} value={newUser.rol} req iterable={ROLES_LIST} />
      </ModalNew>
      <ModalNew
        open={openDisableModal}
        handleClose={() => setOpenDisableModal(!openDisableModal)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "¿Está seguro que quiere desactivar al usuario?",
          accept: "Aceptar",
          description: "Si lo desactiva, el usuario ya no podrá ingresar al sistema. Puede volver a activarlo en cualquier momento.",
        }}
        loading={false}
      />
    </BaseContainer>
  );
};
