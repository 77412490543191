import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, CellTower, Delete, Edit, Save } from "@mui/icons-material";

import { BaseContainer } from "../../components/BaseContainer";
import { BILLING_TYPE_LIST, BillingType } from "../../types/Billing";
import { toSpanish } from "../../helpers/toSpanish";
import { InputDate, InputField, InputNumber, InputPatternNumber, InputRadio, InputSelect } from "../../components/Inputs";
import { FieldDisplay } from "../../components/FieldDisplay";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { connectionList } from "../connections/Connections";
import { paymentList } from "./PaymentList";
import { ActionButton } from "../../components/ActionButton";
import { ModalNew } from "../../components/ModalNew";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { formatToCurrency } from "../../helpers/formats";

export const BillingData = () => {
  let columns: GridColDef[] = [
    {
      field: "type",
      headerName: toSpanish("payment", "type"),
      minWidth: 100,
      flex: 30,
    },
    {
      field: "number",
      headerName: toSpanish("payment", "total"),
      description: "Precio total",
      minWidth: 50,
      flex: 30,
      renderCell: (params) => {
        return formatToCurrency.format(params.row.total);
      },
    },
    {
      field: "total",
      headerName: toSpanish("payment", "total"),
      description: "Precio total",
      minWidth: 50,
      flex: 30,
      renderCell: (params) => {
        return formatToCurrency.format(params.row.total);
      },
    },
    {
      field: "iva",
      headerName: toSpanish("billing", "iva"),
      description: "Impuesto al Valor Agregado",
      minWidth: 20,
      flex: 30,
      renderCell: (params) => {
        return params.row.iva + "%";
      },
    },
    {
      field: "state",
      headerName: toSpanish("payment", "state"),
      minWidth: 100,
      flex: 30,
      renderCell: (params) => {
        return <Chip variant="filled" color="success" label="Pagado" />;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <ActionButton title="Eliminar pago" onClick={() => setOpenDeleteModal(true)} icon={<Delete />} color="error" />
            <ActionButton title="Editar pago" onClick={() => setOpenEditModal(true)} icon={<Edit />} />
          </Stack>
        );
      },
    },
  ];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <>
      <BaseDataGrid rows={paymentList} columns={columns} sx={{ marginTop: "0px !important" }} />
      <ModalNew
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "Eliminar transacción",
          description: "Al eliminar esta transacción la factura pasará como estado NO PAGADO, si hay saldos no procesados estos serán eliminados.",
          accept: "Eliminar pago",
          iconTitle: <Delete />,
        }}
        loading={false}
      />
    </>
  );
};
