import React, { useEffect, useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import { Build, Delete, Groups2, PowerSettingsNew } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";

import { BaseContainer } from "../../components/BaseContainer";
import { toSpanish } from "../../helpers/toSpanish";
import { convertToDate, formatToCurrency } from "../../helpers/formats";
import { CustomerType, CustomerAddressType } from "../../types/Customer";
import { ModalNew } from "../../components/ModalNew";
import { ActionButton } from "../../components/ActionButton";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";

export const CUSTOMER_LIST: CustomerType[] = [
  {
    id: 1,
    dni: "40000000",
    firstname: "Cliente",
    lastname: "de Prueba",
    customer_address: {
      id: 1,
      street: "Calle",
      number: "345",
      city: "Pcia. Roque Sáenz Peña",
      province: "Chaco",
      telephone_1: "3735400000",
      telephone_2: "3735400001",
      email: "clienteprueba@gmail.com",
    },
    created_at: "07/05/2024",
  },
  {
    id: 20000,
    dni: "30000000",
    firstname: "Julio",
    lastname: "Fuentes",
    customer_address: {
      id: 2,
      street: "Lavalle",
      number: "45466",
      city: "Pcia. Roque Sáenz Peña",
      province: "Chaco",
      telephone_1: "3735300000",
      telephone_2: "3735300001",
      email: "juliofuentes@gmail.com",
    },
    created_at: "07/05/2024",
  },
];

export const CustomerList = () => {
  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: toSpanish("general", "id"),
      width: 65,
    },
    {
      field: "dni",
      headerName: toSpanish("customer", "dni"),
      description: "Número de documento",
      width: 90,
    },
    {
      field: "firstname",
      headerName: toSpanish("customer", "firstname"),
      minWidth: 100,
      flex: 40,
      valueGetter: (value, row: CustomerType) => {
        return `${row.lastname}, ${row.firstname}`;
      },
    },
    {
      field: "telephone_1",
      headerName: toSpanish("customer_address", "telephone_1"),
      description: "Télefono de contacto",
      width: 101,
      valueGetter: (value, row: CustomerType) => {
        return row.customer_address.telephone_1;
      },
    },
    {
      field: "customer_address",
      headerName: toSpanish("customer", "customer_address"),
      minWidth: 100,
      flex: 40,
      valueGetter: (value, row: CustomerType) => {
        return `${row.customer_address.city}, ${row.customer_address.street} ${row.customer_address.number}, ${row.customer_address.province}`;
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.customer_address.province}>
            <span>
              {params.row.customer_address.street} {params.row.customer_address.number}, {params.row.customer_address.city}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "ip",
      headerName: toSpanish("connection", "ip"),
      valueGetter: (value, row: CustomerType) => {
        // return row.connection.ip;
        return "192.168.1.8";
      },
    },
    {
      field: "debt",
      headerName: "Deuda",
      minWidth: 110,
      valueGetter: (value, row: CustomerType) => {
        return formatToCurrency.format(1415000);
      },
    },
    {
      field: "service",
      headerName: toSpanish("connection", "service"),
      minWidth: 130,
      flex: 20,
      valueGetter: (value, row: CustomerType) => {
        return "Plan 300MB";
      },
    },
    {
      field: "created_at",
      headerName: toSpanish("general", "created_at"),
      description: "Fecha de alta",
      width: 95,
      valueGetter: (value, row) => {
        return new Date(row.created_at);
      },
      renderCell: (params) => {
        const [date, hour] = convertToDate(params.row.created_at);
        return (
          <Tooltip title={hour}>
            <span>{date}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "actions",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0} px={1}>
            <ActionButton title="Ver detalles" link={"./" + params.row.id} icon={<Build />} size="small" />
            <ActionButton title="Eliminar cliente" onClick={() => handleModal("delete", params.row)} icon={<Delete />} color="error" size="small" />
            <ActionButton
              title="Desactivar cliente"
              onClick={() => handleModal("disable", params.row)}
              icon={<PowerSettingsNew />}
              color="warning"
              size="small"
            />
          </Stack>
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState<"delete" | "disable" | null>(null);
  const [customer, setCustomer] = useState<CustomerType | null>(null);
  const { data: customers, loading } = useAxios<CustomerType[]>({ url: endpoints.customers, initialData: [] });

  function handleModal(type: "delete" | "disable", selectedCustomer: CustomerType) {
    setCustomer(selectedCustomer);
    setOpenModal(type);
  }

  useEffect(() => {
    document.title = "Clientes | CONNECT";
  }, []);

  useEffect(() => {
    if (!openModal) {
      setCustomer(null);
    }
  }, [openModal]);

  return (
    <BaseContainer title="Clientes" icon={<Groups2 />}>
      <BaseDataGrid rows={CUSTOMER_LIST} columns={columns} loading={loading} />
      <ModalNew
        open={openModal === "disable"}
        handleClose={() => setOpenModal(null)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "¿Está seguro que quiere desactivar al cliente?",
          accept: "Aceptar",
          description: `El cliente ${customer?.firstname} ${customer?.lastname} ya no podrá acceder a su conexión de internet.`,
        }}
        loading={false}
      />
      <ModalNew
        open={openModal === "delete"}
        handleClose={() => setOpenModal(null)}
        handleSubmit={() => {}}
        fieldsDialog={{
          title: "Eliminar usuario",
          description: `¿Está seguro que desea eliminar al cliente ${customer?.firstname} ${customer?.lastname}?
          Los datos del clientes serán eliminados junto a todos sus servicios registrados.
          Datos como facturas,pagos,trafico,ticket de soporte, etc. serán conservados.`,
          accept: "Eliminar pago",
          iconTitle: <Delete />,
        }}
        loading={false}
      />
    </BaseContainer>
  );
};
