import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { Login } from "../views/auth/Login";
import { Dashboard } from "../views/Dashboard";
import { NotFound } from "../views/layouts/NotFound";
import { Users } from "../views/users/Users";
import { User } from "../views/users/User";
import { CustomerList } from "../views/customers/CustomerList";
import { Customer } from "../views/customers/Customer";
import { ServiceList } from "../views/services/ServiceList";
import { Service } from "../views/services/Service";
import { Connections } from "../views/connections/Connections";
import { Connection } from "../views/connections/Connection";
import { ConnectionMap } from "../views/connections/ConnectionMap";
import { ClientRegistration } from "../views/ClientRegistration";
import { PaymentList } from "../views/payments/PaymentList";

export const AppRouter = () => {
  return (
    <Router>
      <React.Suspense
        fallback={
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        }
      >
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/usuarios" element={<Users />} />
            <Route path="/usuarios/:idUser" element={<User />} />
            <Route path="/clientes" element={<CustomerList />} />
            <Route path="/clientes/:idCustomer" element={<Customer />} />
            <Route path="/nuevo-cliente" element={<ClientRegistration />} />
            <Route path="/servicios" element={<ServiceList />} />
            <Route path="/servicios/:idService" element={<Service />} />
            <Route path="/conexiones" element={<Connections />} />
            <Route path="/conexiones/:idConnection" element={<Connection />} />
            <Route path="/mapa-conexiones" element={<ConnectionMap />} />
            <Route path="/facturacion" element={<PaymentList />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>

        <ToastContainer />
      </React.Suspense>
    </Router>
  );
};
