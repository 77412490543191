import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Person } from "@mui/icons-material";

import { BaseContainer } from "../../components/BaseContainer";
import { CUSTOMER_LIST } from "./CustomerList";
import { CustomerData } from "./CustomerData";
import { CustomerServices } from "./CustomerServices";
import { BillingData } from "../payments/BillingData";

export const Customer = () => {
  const { idCustomer } = useParams();
  const [tab, setTab] = useState("customer");
  const customer = CUSTOMER_LIST[Number(idCustomer) - 1];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    document.title = "Cliente #" + idCustomer + " | CONNECT";
  }, [idCustomer]);

  return (
    <Grid container>
      <BaseContainer title={`${customer.firstname} ${customer.lastname}`} icon={<Person />}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList variant="fullWidth" onChange={handleChangeTab}>
              <Tab label="Cliente" value="customer" />
              <Tab label="Servicios" value="services" />
              <Tab label="Facturación" value="billing" />
            </TabList>
          </Box>
          <TabPanel value="customer" sx={{ p: 0, py: 2 }}>
            <CustomerData />
          </TabPanel>
          <TabPanel value="services" sx={{ p: 0, py: 2 }}>
            <CustomerServices />
          </TabPanel>
          <TabPanel value="billing" sx={{ p: 0, py: 2 }}>
            <BillingData />
          </TabPanel>
        </TabContext>
      </BaseContainer>
    </Grid>
  );
};
