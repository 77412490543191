import React from "react";
import ReactDOM from "react-dom/client";
import { GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import dayjs from "dayjs";
import "dayjs/locale/es";
// import "mapbox-gl/dist/mapbox-gl.css";

import "./assets/styles/index.css";
import { AppRouter } from "./routers/AppRouter";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
dayjs.locale("es");
const theme = createTheme(
  {
    components: {
      MuiInputBase: {
        defaultProps: {
          disableInjectingGlobalStyles: true,
        },
      },
    },
    typography: {
      allVariants: {
        fontFamily: ["Rubik", "BigJohnPRO", "Montserrat ", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
      },
      h1: {
        fontFamily: ["BigJohnPRO", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 44,
      },
      h2: {
        fontFamily: ["Rubik", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 38,
        fontWeight: "bold",
      },
      h3: {
        fontFamily: ["Rubik", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 32,
        fontWeight: "bolder",
      },
      h4: {
        fontFamily: ["BigJohnPRO", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 28,
      },
      h5: {
        fontFamily: ["BigJohnPRO", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 22,
      },
      h6: {
        fontFamily: ["Rubik", "Open Sans", "Rubik", "Arial", "sans-serif"].join(","),
        fontSize: 20,
      },
    },
    palette: {
      primary: {
        main: "#5510a5",
        light: "#e8dfff",
      },
    },
  },
  esES
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          "@keyframes mui-auto-fill": { from: { display: "block" } },
          "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
        }}
      />
      <AppRouter />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
