import React, { useState } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";

import { InputField, InputNumber, InputPatternNumber } from "../../components/Inputs";
import { NewBillingType } from "../../types/Billing";
import { CITIES, PROVINCES } from "../customers/NewCustomer";

const initialValue: NewBillingType = {
  cuit: "",
  iva: "",
  billing_address: {
    street: "",
    number: "",
    cityId: null,
    provinceId: null,
  },
};

export const NewBilling = () => {
  const [newBilling, setNewBilling] = useState<NewBillingType>(initialValue);

  return (
    <form onSubmit={() => {}}>
      <Grid container spacing={1}>
        <InputPatternNumber grid={4} entity="billing" name="cuit" setValue={setNewBilling} value={newBilling.cuit} format="##-########-#" req />
        <InputNumber grid={4} entity="billing" name="iva" setValue={setNewBilling} value={newBilling.iva} type="percentage" req />
      </Grid>
      <Divider sx={{ mt: 2, mb: 1 }}>
        <Typography variant="h5">Dirección de facturación</Typography>
      </Divider>
      <Grid container spacing={1}>
        <InputField
          grid={{ sm: 8, md: 8, lg: 3.5 }}
          name="street"
          entity="billing_address"
          setValue={setNewBilling}
          value={newBilling.billing_address.street}
          req
          nested
        />
        <InputNumber
          grid={{ sm: 4, md: 4, lg: 1.5 }}
          name="number"
          entity="billing_address"
          setValue={setNewBilling}
          value={newBilling.billing_address.number}
          req
          nested
        />
        <Grid item xs={12} sm={12} md={6} lg={3.5}>
          <Autocomplete
            loading={false}
            options={PROVINCES}
            getOptionLabel={(option) => option.province}
            value={PROVINCES[PROVINCES.findIndex((province) => province.id === newBilling.billing_address.provinceId)] || null}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { id: number; province: string } | null) => {
              if (newValue) {
                setNewBilling((prevState) => {
                  return { ...prevState, billing_address: { ...prevState.billing_address, provinceId: newValue.id } };
                });
              }
            }}
            sx={{ width: "100%", mt: { lg: 1, md: 0 } }}
            openOnFocus
            autoHighlight
            disableClearable
            renderInput={(params) => <TextField {...params} label="Provincia" required />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3.5}>
          <Autocomplete
            loading={false}
            options={CITIES}
            getOptionLabel={(option) => option.city}
            value={CITIES[CITIES.findIndex((city) => city.id === newBilling.billing_address.cityId)] || null}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { id: number; city: string } | null) => {
              if (newValue) {
                setNewBilling((prevState) => {
                  return { ...prevState, billing_address: { ...prevState.billing_address, cityId: newValue.id } };
                });
              }
            }}
            sx={{ width: "100%", mt: { lg: 1, md: 0 } }}
            openOnFocus
            autoHighlight
            disableClearable
            renderInput={(params) => <TextField {...params} label="Ciudad" required />}
          />
        </Grid>
      </Grid>
    </form>
  );
};
