import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, Edit, Save } from "@mui/icons-material";

import { CustomerType, NewCustomerType } from "../../types/Customer";
import { toSpanish } from "../../helpers/toSpanish";
import { InputField, InputNumber } from "../../components/Inputs";
import { FieldDisplay } from "../../components/FieldDisplay";
import { CUSTOMER_LIST } from "./CustomerList";
import { useAxios } from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";
import { DateDisplay } from "../../components/DateDisplay";
import { areValuesEqual } from "../../helpers/functions";

export const CustomerData = () => {
  const { idCustomer } = useParams();
  const [customer, setCustomer] = useState<CustomerType>(CUSTOMER_LIST[Number(idCustomer) - 1]);
  const { loading, data: customerFetched, error } = useAxios<CustomerType>({ url: `${endpoints.branches}/${idCustomer}` });
  const [editing, setEditing] = useState(false);

  const [editingCustomer, setEditingCustomer] = useState<CustomerType>(customer);
  const [putCustomerData, setPutCustomerData] = useState<NewCustomerType>();
  const {
    data: customerModify,
    loading: loadingPutCustomer,
    error: modifyError,
  } = useAxios<CustomerType>({
    url: `${endpoints.customers}/${idCustomer}`,
    method: "put",
    data: putCustomerData,
    msgOnSuccess: "Cliente actualizado",
  });

  function cancelEdit() {
    setEditingCustomer(CUSTOMER_LIST[Number(idCustomer) - 1]);
    setEditing(false);
  }

  function saveEdit() {
    setPutCustomerData({ ...editingCustomer, customer_address: { ...editingCustomer.customer_address, cityId: 0, provinceId: 0 } });
  }

  useEffect(() => {
    setEditingCustomer(customer);
  }, [customer]);

  useEffect(() => {
    if (!modifyError && customerModify) {
      setEditingCustomer(customerModify);
      setEditing(false);
    }
  }, [customerModify, modifyError]);

  return (
    <>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Button variant="outlined" color="warning" startIcon={<Edit />} onClick={() => setEditing(true)} disabled={editing}>
          Editar datos
        </Button>
        <DateDisplay createdAt={editingCustomer?.created_at} />
      </Box>
      <Grid container spacing={2}>
        {editing ? (
          <>
            <InputNumber grid={2} entity="customer" name="dni" setValue={setEditingCustomer} value={editingCustomer?.dni} req disabled={!editing} />
            <InputField grid={5} entity="customer" name="firstname" setValue={setEditingCustomer} value={editingCustomer?.firstname} req disabled={!editing} />
            <InputField grid={5} entity="customer" name="lastname" setValue={setEditingCustomer} value={editingCustomer?.lastname} req disabled={!editing} />
            <InputNumber
              grid={4}
              name="telephone_1"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.telephone_1}
              req
              nested
            />
            <InputNumber
              grid={4}
              name="telephone_2"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.telephone_2}
              req
              nested
            />
            <InputField
              grid={4}
              name="email"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.email}
              req
              nested
            />
            <InputField
              grid={3.5}
              name="street"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.street}
              req
              nested
            />
            <InputNumber
              grid={1.5}
              name="number"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.number}
              req
              nested
            />
            <InputField
              grid={3.5}
              name="city"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.city}
              req
              nested
            />
            <InputField
              grid={3.5}
              name="province"
              entity="customer_address"
              setValue={setEditingCustomer}
              value={editingCustomer?.customer_address.province}
              req
              nested
            />
          </>
        ) : (
          <>
            <FieldDisplay description={toSpanish("customer", "dni")} text={editingCustomer?.dni} grid={2} />
            <FieldDisplay description={toSpanish("customer", "firstname")} text={editingCustomer?.firstname} grid={5} />
            <FieldDisplay description={toSpanish("customer", "lastname")} text={editingCustomer?.lastname} grid={5} />
            <FieldDisplay description={toSpanish("customer_address", "telephone_1")} text={editingCustomer?.customer_address.telephone_1} grid={4} />
            <FieldDisplay description={toSpanish("customer_address", "telephone_2")} text={editingCustomer?.customer_address.telephone_2 || "N/A"} grid={4} />
            <FieldDisplay description={toSpanish("customer_address", "email")} text={editingCustomer?.customer_address.email} grid={4} />
            <FieldDisplay description={toSpanish("customer_address", "street")} text={editingCustomer?.customer_address.street} grid={3.5} />
            <FieldDisplay description={toSpanish("customer_address", "number")} text={editingCustomer?.customer_address.number} grid={1.5} />
            <FieldDisplay description={toSpanish("customer_address", "city")} text={editingCustomer?.customer_address.city} grid={3.5} />
            <FieldDisplay description={toSpanish("customer_address", "province")} text={editingCustomer?.customer_address.province} grid={3.5} />
          </>
        )}
      </Grid>
      {editing && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 2 }}>
          <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={cancelEdit}>
            Cancelar
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            startIcon={<Save />}
            onClick={saveEdit}
            loading={loadingPutCustomer}
            // disabled={areValuesEqual(customer, editingCustomer)}
          >
            Guardar cambios
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
