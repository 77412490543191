import { ReactElement } from "react";
import { Grid, Typography } from "@mui/material";
import { convertToDate, formatToCurrency } from "../helpers/formats";

type FieldType = {
  description: string;
  text: string | ReactElement | number | Date | null;
  grid?: number;
  money?: boolean;
  postsymbol?: string;
};

export const FieldDisplay = ({ description, text, grid = 12, money, postsymbol }: FieldType) => {
  function getField(): ReactElement | string | number {
    if (text) {
      if (text instanceof Date) {
        return convertToDate(text)[0];
      }
      if (money && typeof text === "number") {
        return formatToCurrency.format(text).toString();
      }
      if (postsymbol) {
        return text + postsymbol;
      }
      return text;
    }
    return "N/A";
  }

  return (
    <Grid item xs={12} sm={12} md={grid} lg={grid} xl={grid} display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h5">{description}</Typography>
      <Typography variant="h6">{getField()}</Typography>
    </Grid>
  );
};
