import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { EditCalendar, EventAvailable } from "@mui/icons-material";

type PropsType = {
  createdAt?: string;
  updatedAt?: string;
};

export const DateDisplay = ({ createdAt, updatedAt }: PropsType) => {
  return (
    <Stack direction="row" spacing={3}>
      {createdAt && (
        <Box display="flex" justifyContent="center" gap={0.5}>
          <Tooltip
            title="Creado"
            disableInteractive
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <EventAvailable />
          </Tooltip>
          <strong>{createdAt}</strong>
        </Box>
      )}
      {updatedAt && (
        <Box display="flex" justifyContent="center" gap={0.5}>
          <Tooltip
            title="Modificado"
            disableInteractive
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <EditCalendar />
          </Tooltip>
          <strong>{updatedAt}</strong>
        </Box>
      )}
    </Stack>
  );
};
