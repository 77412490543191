import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Map, Marker } from "react-map-gl";

import { NewConnectionType } from "../../types/Connection";
import { InputDate, InputField, InputNumber, InputPatternNumber, InputSelect } from "../../components/Inputs";
import { serviceList } from "../services/ServiceList";
import { PersonPinCircle } from "@mui/icons-material";
import { emailRegex } from "../../helpers/functions";

const initialValue: NewConnectionType = {
  state: 0,
  connection_date: dayjs(),
  serviceId: "",
  customerId: "",
  connection_address: {
    latitude: "",
    longitude: "",
    street: "",
    number: "",
    telephone_1: "",
    telephone_2: "",
    email: "",
  },
};

export const NewConnection = () => {
  const [newConnection, setNewConnection] = useState<NewConnectionType>(initialValue);
  const [markerPosition, setMarkerPosition] = useState({ latitude: -26.79124947521646, longitude: -60.44106908164302 });

  useEffect(() => {
    console.log(markerPosition);
  }, [markerPosition]);

  return (
    <Box component="form" autoComplete="off" noValidate onSubmit={() => {}}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} alignContent="center">
          <Grid container spacing={1} justifyContent="center">
            <InputDate
              grid={{ md: 4.5, lg: 4 }}
              entity="connection"
              name="connection_date"
              setValue={setNewConnection}
              value={newConnection.connection_date}
              req
            />
            <InputSelect
              grid={{ md: 7.5, lg: 8 }}
              entity="connection"
              name="service"
              setValue={setNewConnection}
              value={newConnection.serviceId}
              req
              iterable={serviceList.map((serv) => {
                return { value: serv.id, text: `${serv.name} - ⬇${serv.download}Mbps/⬆${serv.upload}Mbps` };
              })}
            />
            <Grid item xs={12}>
              <Divider sx={{ mt: 2, mb: 1 }}>
                <Typography variant="h5">Dirección de la conexión</Typography>
              </Divider>
            </Grid>
            <InputField
              grid={{ sm: 8, md: 7.5, lg: 9 }}
              name="street"
              entity="connection_address"
              setValue={setNewConnection}
              value={newConnection.connection_address.street}
              req
              nested
            />
            <InputNumber
              grid={{ sm: 4, md: 4.5, lg: 3 }}
              name="number"
              entity="connection_address"
              setValue={setNewConnection}
              value={newConnection.connection_address.number}
              req
              nested
            />
            <InputPatternNumber
              grid={{ md: 6, lg: 6 }}
              name="telephone_1"
              entity="connection_address"
              setValue={setNewConnection}
              value={newConnection.connection_address.telephone_1}
              req
              nested
              format="(####) ##-####"
            />
            <InputPatternNumber
              grid={{ md: 6, lg: 6 }}
              name="telephone_2"
              entity="connection_address"
              setValue={setNewConnection}
              value={newConnection.connection_address.telephone_2}
              nested
              format="(####) ##-####"
            />
            <InputField
              grid={12}
              name="email"
              entity="connection_address"
              setValue={setNewConnection}
              value={newConnection.connection_address.email}
              email
              error={!emailRegex.test(newConnection.connection_address.email) && newConnection.connection_address.email.length > 1}
              helperText={
                !emailRegex.test(newConnection.connection_address.email) && newConnection.connection_address.email.length > 1
                  ? "El email tiene un formato incorrecto"
                  : ""
              }
              req
              nested
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} alignContent="center">
          <Map
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              latitude: -26.79124947521646,
              longitude: -60.44106908164302,
              zoom: 13,
            }}
            style={{ width: "100%", height: "400px" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
          >
            <Marker
              latitude={markerPosition.latitude}
              longitude={markerPosition.longitude}
              anchor="bottom"
              draggable
              onDragEnd={(e) =>
                setMarkerPosition((prevState) => ({
                  ...prevState,
                  latitude: e.lngLat.lat,
                  longitude: e.lngLat.lng,
                }))
              }
              // ref={markerRef as React.LegacyRef<mapboxgl.Marker>}
            >
              <PersonPinCircle sx={{ fontSize: "3rem" }} color="secondary" />
            </Marker>
          </Map>
        </Grid>
      </Grid>
    </Box>
  );
};
