import React, { CSSProperties } from "react";

interface StyleProps {
  container: CSSProperties;
  image: CSSProperties;
}

const styles: StyleProps = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "black",
  },
  image: {
    marginBottom: "20px",
  },
};

export const NotFound = () => {
  return (
    <div style={styles.container}>
      <center>
        <h1 style={{ color: "white" }}>Página no encontrada.</h1>
      </center>
    </div>
  );
};
