export const baseUrl = `http://54.242.132.54/:8000/api`;

const endpoints = {
  login: "/auth/login",
  registerUser: "/auth/signup",
  getDataUser: "/users/me",
  getUsers: "/users/list",

  customers: "/Customer",
  customerAddress: "/CustomerAddress",

  connections: "/Connection",
  connectionAddress: "/ConnectionAddress",

  services: "/Service",

  branches: "/Sucursal",
  branchAddress: "/SucursalAddress",

  servers: "/Server",
  ipDesignation: "/IpDesignation",
};
export default endpoints;
