import React, { FunctionComponent } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, SxProps, Theme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type PropsType = {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
  fieldsDialog: {
    title?: string;
    accept?: string;
    description?: string;
    iconTitle?: React.ReactNode;
    acceptDisabled?: boolean;
  };
  children?: React.ReactNode;
  loading: boolean;
  styles?: SxProps<Theme>;
};

export const ModalNew: FunctionComponent<PropsType> = ({ open, handleClose, handleSubmit, fieldsDialog, children, loading, styles }) => {
  return (
    <Dialog open={open} onClose={handleClose} sx={{ ".MuiPaper-root": styles ? { maxWidth: "90vw" } : "inherit" }}>
      <DialogTitle sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        {fieldsDialog?.iconTitle}
        {fieldsDialog?.title}
      </DialogTitle>
      <Divider />
      <Box sx={styles}>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <DialogContent>
            {fieldsDialog?.description && <DialogContentText>{fieldsDialog?.description}</DialogContentText>}
            <Grid container>{children}</Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <LoadingButton type="submit" loading={loading} disabled={fieldsDialog?.acceptDisabled} color="success">
              {fieldsDialog?.accept}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};
