import React, { useEffect, useState } from "react";
import { Box, Stepper, Step, StepLabel, Button, Divider } from "@mui/material";
import { Cable } from "@mui/icons-material";

import { BaseContainer } from "../components/BaseContainer";
import { NewCustomer } from "./customers/NewCustomer";
import { NewConnection } from "./connections/NewConnection";
import { NewBilling } from "./payments/NewBilling";
import { notificationToast } from "../helpers/toastDisplay";

const steps = ["Cliente", "Conexión", "Factura"];

export const ClientRegistration = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      notificationToast({ msg: "¡Conexión creada!" });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <NewCustomer />;
      case 1:
        return <NewConnection />;
      case 2:
        return <NewBilling />;
      default:
        throw new Error("Paso desconocido");
    }
  }

  useEffect(() => {
    document.title = "Alta de cliente | CONNECT";
  }, []);

  return (
    <BaseContainer title="Nuevo cliente" icon={<Cable />}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Divider sx={{ mt: 2, mb: 1 }} />
      {getStepContent(activeStep)}
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} tabIndex={-1}>
          Atrás
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext}>{activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}</Button>
      </Box>
    </BaseContainer>
  );
};
