import React, { ReactNode } from "react";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, gridClasses } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";
import { DataGridProps } from "@mui/x-data-grid/internals";
import { Stack, styled } from "@mui/material";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.b-gray`]: {
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: "none",
      "@media (hover: none)": {
        backgroundColor: "none",
      },
    },
  },
  [`& .${gridClasses.row}.b-white`]: {
    "&:hover": {
      backgroundColor: "white",
      "@media (hover: none)": {
        backgroundColor: "white",
      },
    },
  },
}));

function CustomToolbar({ buttons }: { buttons?: ReactNode[] }) {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack direction="row" spacing={1}>
        {buttons?.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </Stack>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

interface Props extends DataGridProps {
  buttons?: ReactNode[];
}

export const BaseDataGrid = (props: Props) => {
  return (
    <StripedDataGrid
      {...props}
      autoHeight
      ignoreDiacritics
      rowBufferPx={10}
      pageSizeOptions={[10, 25, 50]}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      slots={{ toolbar: () => <CustomToolbar buttons={props.buttons} /> }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      showCellVerticalBorder
      density="compact"
      sx={{
        fontSize: "0.8rem",
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        border: "none",
        "& .MuiDataGrid-main": {
          border: "1px solid rgba(224, 224, 224, 1)",
        },
        ...props.sx,
      }}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "b-gray" : "b-white")}
    />
  );
};
