import React, { useState } from "react";
import { Autocomplete, Box, Divider, Grid, TextField, Typography } from "@mui/material";

import { InputField, InputNumber, InputPatternNumber } from "../../components/Inputs";
import { NewCustomerType } from "../../types/Customer";
import { emailRegex } from "../../helpers/functions";

const initialValue: NewCustomerType = {
  dni: "",
  firstname: "",
  lastname: "",
  customer_address: {
    street: "",
    number: "",
    provinceId: null,
    cityId: null,
    telephone_1: "",
    telephone_2: "",
    email: "",
  },
};

export const PROVINCES = [
  { id: 1, province: "Chaco" },
  { id: 2, province: "Santa Fe" },
  { id: 3, province: "Buenos Aires" },
];

export const CITIES = [
  { id: 1, city: "Pcia. Roque Sáenz Peña" },
  { id: 2, city: "Quitilipi" },
  { id: 3, city: "San Bernardo" },
];

export const NewCustomer = () => {
  const [newCustomer, setNewCustomer] = useState<NewCustomerType>(initialValue);

  return (
    <Box component="form" autoComplete="off" noValidate onSubmit={() => {}}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container justifyContent="center">
            <InputNumber
              grid={6}
              entity="customer"
              name="dni"
              setValue={setNewCustomer}
              value={newCustomer.dni}
              error={newCustomer.dni.length > 8}
              helperText={newCustomer.dni.length > 8 ? "El DNI no puede tener más de 8 dígitos" : ""}
              req
            />
            <InputField grid={12} entity="customer" name="firstname" setValue={setNewCustomer} value={newCustomer.firstname} req />
            <InputField grid={12} entity="customer" name="lastname" setValue={setNewCustomer} value={newCustomer.lastname} req />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container>
            <InputPatternNumber
              grid={12}
              name="telephone_1"
              entity="customer_address"
              setValue={setNewCustomer}
              value={newCustomer.customer_address.telephone_1}
              req
              nested
              format="(####) ##-####"
            />
            <InputPatternNumber
              grid={12}
              name="telephone_2"
              entity="customer_address"
              setValue={setNewCustomer}
              value={newCustomer.customer_address.telephone_2}
              nested
              format="(####) ##-####"
            />
            <InputField
              grid={12}
              name="email"
              entity="customer_address"
              setValue={setNewCustomer}
              value={newCustomer.customer_address.email}
              email
              error={!emailRegex.test(newCustomer.customer_address.email) && newCustomer.customer_address.email.length > 1}
              helperText={
                !emailRegex.test(newCustomer.customer_address.email) && newCustomer.customer_address.email.length > 1
                  ? "El email tiene un formato incorrecto"
                  : ""
              }
              req
              nested
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 1 }}>
        <Typography variant="h5">Dirección principal</Typography>
      </Divider>
      <Grid container spacing={1}>
        <InputField
          grid={{ sm: 8, md: 8, lg: 3.5 }}
          name="street"
          entity="customer_address"
          setValue={setNewCustomer}
          value={newCustomer.customer_address.street}
          req
          nested
        />
        <InputNumber
          grid={{ sm: 4, md: 4, lg: 1.5 }}
          name="number"
          entity="customer_address"
          setValue={setNewCustomer}
          value={newCustomer.customer_address.number}
          req
          nested
        />
        <Grid item xs={12} sm={12} md={6} lg={3.5}>
          <Autocomplete
            loading={false}
            options={PROVINCES}
            getOptionLabel={(option) => option.province}
            value={PROVINCES[PROVINCES.findIndex((province) => province.id === newCustomer.customer_address.provinceId)] || null}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { id: number; province: string } | null) => {
              if (newValue) {
                setNewCustomer((prevState) => {
                  return { ...prevState, customer_address: { ...prevState.customer_address, provinceId: newValue.id } };
                });
              }
            }}
            sx={{ width: "100%", mt: { lg: 1, md: 0 } }}
            openOnFocus
            autoHighlight
            disableClearable
            renderInput={(params) => <TextField {...params} label="Provincia" required />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3.5}>
          <Autocomplete
            loading={false}
            options={CITIES}
            getOptionLabel={(option) => option.city}
            value={CITIES[CITIES.findIndex((city) => city.id === newCustomer.customer_address.cityId)] || null}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: { id: number; city: string } | null) => {
              if (newValue) {
                setNewCustomer((prevState) => {
                  return { ...prevState, customer_address: { ...prevState.customer_address, cityId: newValue.id } };
                });
              }
            }}
            sx={{ width: "100%", mt: { lg: 1, md: 0 } }}
            openOnFocus
            autoHighlight
            disableClearable
            renderInput={(params) => <TextField {...params} label="Ciudad" required />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
