import { useEffect, useState } from "react";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { notificationToast } from "../helpers/toastDisplay";
import { baseUrl } from "../helpers/endpoints";
import { authHeader } from "../helpers/auth";

const api: AxiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      if (error.response.data.message === "Unauthorized") {
        notificationToast({ msg: "Inicia sesión para ver esta página", type: "error" });
        window.location.replace("/");
      } else {
        if (error.response.data.message === "Internal server error") {
          notificationToast({ msg: "Ocurrió un error en el servidor", type: "error" });
        } else {
          notificationToast({
            msg: Array.isArray(error.response.data.message) ? error.response.data.message.join(", ") : error.response.data.message,
            type: "error",
          });
        }
      }
    } else if (error.request) {
      // La solicitud fue hecha pero no se recibió respuesta
      console.log(error.request);
      notificationToast({ msg: "No se recibió respuesta del servidor: " + error.message, type: "error" });
    } else {
      console.log(error.request);
      // Ocurrió un error al configurar la solicitud
      notificationToast({ msg: "Error al configurar la solicitud: " + error.message, type: "error" });
    }
    return Promise.reject(error);
  }
);

interface UseAxiosResult<T = any> {
  data: T;
  loading: boolean;
  error: boolean;
  refetch: (finishAwait?: boolean) => Promise<void>;
}

interface UseAxiosSet<T = any> {
  url: string;
  method?: "get" | "put" | "post" | "delete";
  auth?: boolean;
  data?: any;
  config?: AxiosRequestConfig;
  initialData?: T;
  awaiting?: boolean;
  msgOnSuccess?: string;
}

export const useAxios = <T = any>({
  url,
  method = "get",
  auth = true,
  data,
  config,
  initialData,
  msgOnSuccess,
  awaiting = false,
}: UseAxiosSet): UseAxiosResult<T> => {
  const [responseData, setResponseData] = useState<T | AxiosResponse<T, any>>(initialData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const { headers } = authHeader(config?.headers?.["Content-Type"] ? config.headers["Content-Type"] : undefined);

  const fetchData = async (finishAwait: boolean = false): Promise<void> => {
    if (((method === "post" || method === "put") && !data) || (awaiting && !finishAwait)) {
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const response: AxiosResponse<T> = await api({
        method,
        url,
        data,
        ...config,
        headers: auth ? headers : undefined,
      });
      if (config?.responseType === "blob") {
        setResponseData(response);
      } else {
        setResponseData(response.data);
      }
      if (msgOnSuccess) {
        notificationToast({ msg: msgOnSuccess, type: "success" });
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, data, config]);

  return {
    loading,
    data: responseData as T,
    error,
    refetch: (finishAwait = true) => fetchData(finishAwait),
  };
};
