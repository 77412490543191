import React, { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, Divider, Tooltip, Typography } from "@mui/material";
import { Cable, CellTower, ExpandMore, Groups2, KeyboardArrowRight, LocalAtm, Logout, Map, PeopleAlt } from "@mui/icons-material";

import "../../assets/styles/sidebar.css";
import { RolesType } from "../../types/User";
import logo from "../../assets/images/logo-min.png";
import { toSpanish } from "../../helpers/toSpanish";

interface OptionsType {
  icon: ReactElement;
  description: string;
  roles: RolesType[];
  link: string;
  options?: OptionsType[];
}

const isActiveRoute = (route: string, currentPath: string) => {
  if (route === "/") {
    return currentPath === "/";
  }
  return currentPath.startsWith(route);
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarState = "sidebar " + (localStorage.getItem("sidebar") ? localStorage.getItem("sidebar") : "");
  const [openSidebar, setOpenSidebar] = useState(Boolean(localStorage.getItem("sidebar") !== "close"));

  const user: { name: string; lastname: string; rol: RolesType } = { name: "Usuario", lastname: "De Prueba", rol: "admin" };

  const options: OptionsType[] = [
    {
      icon: <Cable />,
      description: "Nuevo cliente",
      roles: ["admin"],
      link: "/nuevo-cliente",
    },
    {
      icon: <Map />,
      description: "Ver en el mapa",
      roles: ["admin", "operator"],
      link: "/mapa-conexiones",
    },
    {
      icon: <PeopleAlt />,
      description: "Usuarios",
      roles: ["admin"],
      link: "/usuarios",
    },
    {
      icon: <CellTower />,
      description: "Servicios",
      roles: ["admin", "operator"],
      link: "/servicios",
    },
    {
      icon: <Groups2 />,
      description: "Clientes",
      roles: ["admin", "operator"],
      link: "/clientes",
    },
    {
      icon: <LocalAtm />,
      description: "Facturación",
      roles: ["admin", "operator"],
      link: "/facturacion",
    },
  ];

  const toggleSidebar = () => {
    if (localStorage.getItem("sidebar")) {
      localStorage.removeItem("sidebar");
      setOpenSidebar(true);
    } else {
      localStorage.setItem("sidebar", "close");
      setOpenSidebar(false);
    }
    (document.querySelector("nav") as HTMLElement).classList.toggle("close");
    var nameSection = document.getElementById("name") as HTMLElement;
    nameSection.style.display = nameSection.style.display === "none" ? "" : "none";
  };

  const activeRoutes = useMemo(() => {
    return options.reduce<Record<string, boolean>>((acc, option) => {
      acc[option.link] = isActiveRoute(option.link, location.pathname);
      if (option.options) {
        option.options.forEach((subOption) => {
          if (subOption.link) {
            acc[subOption.link] = isActiveRoute(subOption.link, location.pathname);
          }
        });
      }
      return acc;
    }, {});
  }, [location.pathname]);

  const renderTooltip = useCallback(
    (element: ReactElement, description: string) => {
      if (openSidebar) {
        return element;
      }
      return (
        <Tooltip title={description} placement="right" disableInteractive arrow>
          {element}
        </Tooltip>
      );
    },
    [openSidebar]
  );

  return (
    <nav className={sidebarState}>
      <header>
        <div className="image-text">
          <span className="image">
            <img src={logo} alt="Logo Connect" />
          </span>
          <div className="text header-text">
            <span className="name">CONNECT SRL</span>
          </div>
        </div>
        <KeyboardArrowRight className="toggle" onClick={toggleSidebar} />
      </header>
      <div id="name" style={{ display: localStorage.getItem("sidebar") ? "none" : "" }}>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Typography align="center" className="text">
          {user.name} {user.lastname}
        </Typography>
        <Typography align="center" className="text">
          - {toSpanish("user_role", user.rol)} -
        </Typography>
      </div>
      <Divider sx={{ mt: 2 }} />
      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            {options.map((option) => {
              if (option.roles.includes(user.rol)) {
                return (
                  <React.Fragment key={option.link}>
                    {option.link &&
                      renderTooltip(
                        <li className={`nav-link ${activeRoutes[option.link] ? "active" : ""}`}>
                          <div onClick={() => navigate(option.link)}>
                            {option.icon}
                            <span className="text nav-text">{option.description}</span>
                          </div>
                        </li>,
                        option.description
                      )}
                    {option.options && (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          {renderTooltip(
                            <li className="nav-link">
                              <div>
                                {option.icon}
                                <span className="text nav-text">{option.description}</span>
                              </div>
                            </li>,
                            option.description
                          )}
                        </AccordionSummary>
                        {option.options?.map((op) =>
                          renderTooltip(
                            <li className={`nav-link ${activeRoutes[op.link] ? "active" : ""}`} key={op.link}>
                              <div onClick={() => navigate(op.link)}>
                                {op.icon}
                                <span className="text nav-text">{op.description}</span>
                              </div>
                            </li>,
                            op.description
                          )
                        )}
                      </Accordion>
                    )}
                  </React.Fragment>
                );
              } else return null;
            })}
          </ul>
        </div>
        <div className="bottom-content">
          <Tooltip title={openSidebar ? "" : "Cerrar sesión"} placement="right" disableInteractive arrow open={openSidebar}>
            <li className="nav-link">
              <div
                onClick={() => {
                  localStorage.removeItem("jwt");
                  window.location.replace("/");
                  navigate("#salir");
                }}
              >
                <Logout />
                <span className="text nav-text">Cerrar sesión</span>
              </div>
            </li>
          </Tooltip>
        </div>
      </div>
    </nav>
  );
};
