import { TypeOptions, toast } from "react-toastify";

type Props = {
  msg: string;
  type?: TypeOptions;
};

export const notificationToast = ({ msg, type = "success" }: Props) => {
  toast(msg, {
    type,
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    bodyStyle: { fontFamily: "Rubik" },
  });
};
