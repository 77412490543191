import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, CellTower, Edit, Save } from "@mui/icons-material";

import { BaseContainer } from "../../components/BaseContainer";
import { NewServiceType, ServiceType } from "../../types/Service";
import { toSpanish } from "../../helpers/toSpanish";
import { serviceList } from "./ServiceList";
import { InputField, InputNumber } from "../../components/Inputs";
import { FieldDisplay } from "../../components/FieldDisplay";
import endpoints from "../../helpers/endpoints";
import { useAxios } from "../../hooks/useAxios";
import { areValuesEqual } from "../../helpers/functions";

export const Service = () => {
  const { idService } = useParams();
  const [service, setService] = useState<ServiceType>(serviceList[Number(idService) - 1]);
  // const { loading, data: service, error } = useAxios<ServiceType>({ url: `${endpoints.services}/${idService}` });
  const [editing, setEditing] = useState(false);

  const [editingService, setEditingService] = useState<ServiceType>(service);
  const [putServiceData, setPutServiceData] = useState<NewServiceType>();
  const {
    data: serviceModify,
    loading: loadingPutService,
    error: modifyError,
  } = useAxios<ServiceType>({
    url: `${endpoints.services}/${idService}`,
    method: "put",
    data: putServiceData,
    msgOnSuccess: "Servicio actualizado",
  });

  function cancelEdit() {
    setService(serviceList[Number(idService) - 1]);
    setEditing(false);
  }

  function saveEdit() {
    setPutServiceData({ ...editingService, price: editingService.price.toString(), iva: editingService.iva.toString() });
  }

  useEffect(() => {
    document.title = "Servicio #" + idService + " | CONNECT";
  }, [idService]);

  useEffect(() => {
    setEditingService(service);
  }, [service]);

  useEffect(() => {
    if (!modifyError && serviceModify) {
      setEditingService(serviceModify);
      setEditing(false);
    }
  }, [serviceModify, modifyError]);

  return (
    <BaseContainer title={"Servicio #" + idService} icon={<CellTower />}>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Button variant="outlined" color="warning" startIcon={<Edit />} onClick={() => setEditing(true)} disabled={editing}>
          Editar datos
        </Button>
      </Box>
      <Grid container spacing={2}>
        {editing ? (
          <>
            <InputField grid={12} entity="service" name="name" setValue={setService} value={service.name} req />
            <InputField grid={12} entity="service" name="description" setValue={setService} value={service.description} multi req />
            <InputNumber grid={6} entity="service" name="upload" setValue={setService} value={service.upload} req />
            <InputNumber grid={6} entity="service" name="download" setValue={setService} value={service.download} req />
            <InputNumber grid={4} entity="service" name="price" setValue={setService} value={service.price} type="money" req />
            <InputNumber grid={4} entity="service" name="iva" setValue={setService} value={service.iva} type="percentage" req />
          </>
        ) : (
          <>
            <FieldDisplay description={toSpanish("service", "name")} text={service.name} grid={12} />
            <FieldDisplay description={toSpanish("service", "description")} text={service.description} grid={12} />
            <FieldDisplay description={toSpanish("service", "upload")} text={service.upload} grid={6} />
            <FieldDisplay description={toSpanish("service", "download")} text={service.download} grid={6} />
            <FieldDisplay description={toSpanish("service", "price")} text={service.price} grid={4} money />
            <FieldDisplay description={toSpanish("service", "iva")} text={service.iva} grid={4} postsymbol="%" />
          </>
        )}
      </Grid>

      {editing && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 2 }}>
          <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={cancelEdit}>
            Cancelar
          </Button>
          <LoadingButton
            variant="outlined"
            color="success"
            startIcon={<Save />}
            onClick={saveEdit}
            loading={loadingPutService}
            // disabled={areValuesEqual(service, editingService)}
          >
            Guardar cambios
          </LoadingButton>
        </Box>
      )}
    </BaseContainer>
  );
};
