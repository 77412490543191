import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export function convertToDate(date: string | Date) {
  return [dayjs(date).format("DD/MM/YYYY"), dayjs(date).format("HH:mm")];
}

export function convertToFullDate(date: string | Date) {
  return dayjs(date).format("DD/MM/YYYY, HH:mm");
}

export const formatToCurrency = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });
